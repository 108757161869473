import React, {MouseEventHandler} from 'react';
import slugify from 'slugify';
import {RichText} from 'prismic-reactjs';
import {digital_album_product, wishlist_item, cart_item, device_target} from './ui';
import {defaultLang, browserLocaleMapping, getProductPagePrefixMapping, stores} from '../configs/site';
import {
    build_age_recommendation,
    build_hardware,
    build_pack,
    build_product,
    build_theme,
    build_type,
    module, prepared_product,
    rawmodule,
    rawproduct
} from './types';
import * as propMappers from './services/property-mappers';
import * as moduleConverters from './services/module-converters';

export const localeToCatalog = (locale?: string) => locale?.replace('-', '_').replace(/..$/, (x) => x.toUpperCase());
export const catalogToLocale = (catalog?: string) => catalog?.replace('_', '-').toLowerCase();

export function getRedirectLanguage(navigator: any) {
    const l = (
        (navigator && navigator.language && navigator.language) ||
        defaultLang
    ).toLowerCase() as string;
    // @ts-ignore
    return browserLocaleMapping[l] || browserLocaleMapping[l.split('-')[0]] || defaultLang;
}

export function slugit(x: string | undefined) {
    return x ? slugify(x).toLowerCase() : undefined;
}

export async function productspecize(x: any | undefined) {
    if (
        !x ||
        !x.uid ||
        !x.document ||
        !x.document.data ||
        !x.document.data.body ||
        !x.document.data.body.length
    )
        return undefined;
    return {
        items: x.document.data.body.map((item: any) => ({
            excludedLocales: item.primary?.excluded_locales,
            title: richtextize(item.primary?.title),
            items: (item.items || []).map((subitem: any) => ({
                excludedLocales: subitem.excluded_locales,
                content: richtextize(subitem.content),
                label: richtextize(subitem.label),
            })),
        })),
    };
}

export function themeize(...args: (any | undefined)[]) {
    return args
        .map((x) => {
            if (!x) return undefined;
            if (x === 'object') {
                if (x.uid) return x.uid;
                return undefined;
            }
            if (typeof x === 'string') {
                if (x.indexOf('[') !== -1) {
                    x = x.replace(/^.*\[([^\]]+)\].*$/, '$1');
                }
                if (x === 'none') x = undefined;
                return x;
            }
            return undefined;
        })
        .find((x) => !!x);
}
export function textize(x: any | undefined) {
    return x; // @todo ?
}

export function richtextize(x: any | undefined) {
    return x ? x?.raw : undefined;
}

export function prioritize(x: string | number | undefined | null) {
    return (typeof x === 'number' ? x : parseInt(x as string)) || 0;
}

export function menuize(x: any | undefined) {
    if (
        !x ||
        !x.uid ||
        !x.document ||
        !x.document.data ||
        !x.document.data.body ||
        !x.document.data.body.length
    )
        return undefined;
    return x.document.data.body.map((item: any) => ({
        label: richtextize(item.primary?.label),
        priority: prioritize(item.primary?.priority),
        target: targetize(item.primary?.target),
        targetUid: item.primary?.target?.uid,
        excludedLocales: item.primary?.excluded_locales,
        items: (item.items || []).map((subitem: any) => ({
            excludedLocales: subitem.excluded_locales,
            label: richtextize(subitem.label),
            target: targetize(subitem.target),
            priority: prioritize(subitem.priority),
            targetUid: subitem?.target?.uid,
        })),
    }));
}

export function imagize(x: any | undefined) {
    if (!x || !x.url) return undefined;
    const {dimensions = {}, url, thumbnails, ...rest} = x;
    return {
        ...rest,
        ...dimensions,
        ...thumbnails,
        url,
    };
}

export function targetize(v: any | undefined) {
    if (!v) return undefined;
    if (typeof v === 'string') return v;
    if (typeof v === 'object') {
        if (v.uid && v.lang && v.type) linkResolver(v);
        if (v.url) return v.url;
    }
    return undefined;
}

export function slugize(x: string | undefined) {
    return slugit(x);
}

export async function configize(
    module: module,
    definition: string,
    rawmodule: rawmodule,
): Promise<module> {
    // @todo based on the name of the definition fetch data and probably populate items property (array)
    return {
        ...module,
    };
}
export function datetimeize(x: string | undefined) {
    return x; // @todo something ?
}

export function linkResolver(doc: any) {
    if (!doc) return '/';
    doc = doc._meta || doc;

    if (doc.type === 'page' || doc.link_type === 'Document') {
        return `/${doc.lang || defaultLang}/${
            doc.uid === 'homepage' ? '' : doc.uid ? `${doc.uid}/` : ''
        }`;
    }
    if (doc.link_type === 'Web') {
        return doc.url;
    }
    if (doc.link_type === 'Media') {
        return doc.url;
    }

    return '/';
}

export function serializeHyperLink(
    type: string,
    element: any,
    content: any,
    children: any,
    index: any,
) {
    const isMailTo = element.type === 'hyperlink' && element?.data?.url?.includes('mailto');
    const onClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
        // @ts-ignore
        window?.dataLayer?.push({
            event: 'ga_evenement',
            categorie: 'lien',
            action: isMailTo ? 'mailto' : 'sortant',
            libelle: content,
            valeur: undefined,
            non_interaction: false,
            eventCallback() {
                // @ts-ignore
                window?.dataLayer?.push({
                    donnees_ecommerce: undefined,
                    categorie: undefined,
                    action: undefined,
                    libelle: undefined,
                    valeur: undefined,
                });
            },
        });
    };
    return (
        !!content && (
            <a
                href={element.data.url}
                key={index}
                onClick={onClick}
                target="_blank"
                rel="noopener noreferrer"
            >
                {children}
            </a>
        )
    );
}

export function richText2react(v: any) {
    const value = undefined;
    if (!Array.isArray(v)) return value;
    switch (v.length) {
        case 0:
            return undefined;
        case 1:
            if (v[0].type === 'paragraph' && (!v[0].spans || !v[0].spans.length)) return v[0].text;
            return (
                <RichText
                    render={v}
                    serializeHyperlink={serializeHyperLink}
                />
            );
        default:
            return (
                <RichText
                    render={v}
                    serializeHyperlink={serializeHyperLink}
                />
            );
    }
}

export function camelCase(s: string) {
    return `${s.slice(0, 1).toUpperCase()}${s.slice(1)}`;
}

export function buildComponentName(...args: string[]): string {
    return args
        .map(camelCase as any)
        .join('')
        .split(/[-_ ]+/g)
        .map(camelCase as any)
        .join('');
}

/**
 * Example
 * module is items
 * mode is mosaic tiles
 * component name must be ItemsMosaicTiles
 *
 * @param module
 * @param mode
 * @param defaultModeName
 */
export function formatModuleModeComponentName(
    module: string,
    mode: string,
    defaultModeName: string = 'Default',
): string[] {
    const name = buildComponentName(mode === 'default' ? defaultModeName : mode, module, 'Module');
    const defaultName = buildComponentName(defaultModeName, module, 'Module');

    return name === defaultName ? [name] : [name, defaultName];
}

export function formatLng(value: string) {
    const [a, b] = value.split('-');
    return `${a}-${b.toUpperCase()}`;
}

export function isBrowser() {
    return typeof window !== 'undefined';
}

export function convertModuleDataToProps(
    data: {[key: string]: any},
    config: {[key: string]: any},
    itemConfig: {[key: string]: any},
) {
    let d = {...data};
    const processedKeys: {[key: string]: any} = {};
    const mapperContext: {[key: string]: any} = {};
    d = Object.entries(propMappers).reduce((acc, [mapperName, mapper]: [string, Function]) => {
        const propName = `${mapperName}Props`;
        return (config[propName] || []).reduce((acc2: any, p: any) => {
            const v = (d[p] === null ? undefined : d[p]) || undefined;
            delete d[p];
            mapper(v, acc2, p, mapperContext);
            processedKeys[propName] = true;
            return acc2;
        }, acc);
    }, d);
    if (d.items && d.items.length) {
        d.items = d.items.map((item: any) => {
            item = {...item};
            return Object.entries(propMappers).reduce(
                (acc, [mapperName, mapper]: [string, Function]) => {
                    const propName = `${mapperName}Props`;
                    return (itemConfig[propName] || []).reduce((acc2: any, p: any) => {
                        const v = (item[p] === null ? undefined : item[p]) || undefined;
                        delete item[p];
                        mapper(v, acc2, p, mapperContext);
                        processedKeys[propName] = true;
                        return acc2;
                    }, acc);
                },
                item,
            );
        });
    }
    return d;
}

export const getTargetDevice = (deviceTarget?: device_target, merge = true) => {
    if (!deviceTarget) return undefined;
    if (merge && deviceTarget.includes('fah') && deviceTarget.includes('flam')) return 'fah_flam';
    if (deviceTarget.includes('fah')) return 'fah';
    if (deviceTarget.includes('flam')) return 'flam';
    return undefined;
}

export function mapProductToProductPagePrefix(product: any) {
    const lang = (product.catalog || '').replace('_', '-').toLowerCase();
    return getProductPagePrefixMapping(lang, getTargetDevice(product?.deviceTarget, false)).replace('{lang}', lang);
}

export async function convertPageModule(def: any, page: any, ctx: any): Promise<module | null> {
    const moduleType = (def.slice_type || def.type || 'unknown').replace(/[-_ ]+/g, '_');
    const module: module = await (
        (moduleConverters as any)[moduleType] || (moduleConverters as any).unknown
    )({...def, moduleType}, page, ctx);
    if (!module) return null;
    module.config = module.config || {};
    module.data = module.data || {};
    module.data.theme = themeize(
        module.data?.theme,
        def?.primary?.theme_uid,
        def?.primary?.theme?.uid,
    );
    module.data.product = await convertProductForLevel(
        module.data?.product || def?.primary?.product,
        'module',
        ctx,
        page.lang
    );
    return nullify<module>(module) as module;
}

export function nullify<T extends object | any[] | null | undefined>(
    value: T,
): object | any[] | null {
    if (undefined === value) return null;
    if (value === null) return null;
    if (Array.isArray(value)) {
        return value.map((x) => nullify(x));
    }
    if (typeof value === 'object') {
        const entries: Array<[string, object | null]> = Object.entries(value);
        return entries.reduce((acc, [k, v]) => Object.assign(acc, {[k]: nullify(v)}), {});
    }
    return value;
}

export function convertLangToCatalog(lang: string) {
    const [a, b] = lang.split('-');
    return `${a}_${b.toUpperCase()}`;
}

export function chunk<T = any>(array: T[], size: number): T[][] {
    const c = [];
    let i = 0;
    while (i < array.length) {
        c.push(array.slice(i, i + size));
        i += size;
    }
    return c;
}

export function buildHeadFromData(
    {
        charset = 'utf-8',
        viewport = 'width=device-width, initial-scale=1.0',
        seo_title = undefined,
        seo_description = undefined,
        seo_robots = undefined,
        seo_keywords = undefined,
        seo_author = undefined,
        seo_subject = undefined,
        seo_copyright = undefined,
        seo_language = undefined,
        seo_abstract = undefined,
        seo_designer = undefined,
        http_refresh = undefined,
        http_content_security_policy = undefined,
        http_content_type = 'text/html; charset=utf-8',
        http_x_ua_compatible = 'IE=edge',
        twitter_card = undefined,
        twitter_site = undefined,
        twitter_title = undefined,
        twitter_description = undefined,
        twitter_creator = undefined,
        opengraph_url = undefined,
        opengraph_type = undefined,
        opengraph_title = undefined,
        opengraph_locale = undefined,
        opengraph_description = undefined,
        opengraph_image = undefined,
        opengraph_locale_alternate = undefined,
        opengraph_site_name = undefined,
        opengraph_determiner = undefined,
        opengraph_video = undefined,
        opengraph_audio = undefined,
        facebook_app_id = undefined,
        facebook_page_id = undefined,
    }: any = {},
    alternate_languages: {lang: string; uid: string; label: string; type: string}[] = [],
    current?: {lang: string; uid: string; type: string, productDeviceTarget?: device_target},
) {
    const lang = current?.lang?.split('-')[0];
    return {
        html: lang ? {props: {lang}} : undefined,
        base: [].filter((x) => !!x),
        body: undefined,
        title: seo_title,
        meta: [
            charset && {type: 'charset', charset},
            http_x_ua_compatible && {
                type: 'http-equiv',
                name: 'x-ua-compatible',
                content: http_x_ua_compatible,
            },
            http_content_type && {
                type: 'http-equiv',
                name: 'content-type',
                content: http_content_type,
            },
            http_refresh && {type: 'http-equiv', name: 'refresh', content: http_refresh},
            http_content_security_policy && {
                type: 'http-equiv',
                name: 'content-security-policy',
                content: http_content_security_policy,
            },
            viewport && {name: 'viewport', content: viewport},
            seo_description && {name: 'description', content: seo_description},
            seo_keywords && {name: 'keywords', content: seo_keywords},
            seo_author && {name: 'author', content: seo_author},
            seo_subject && {name: 'subject', content: seo_subject},
            seo_copyright && {name: 'copyright', content: seo_copyright},
            seo_language && {name: 'language', content: seo_language},
            seo_abstract && {name: 'abstract', content: seo_abstract},
            seo_designer && {name: 'designer', content: seo_designer},
            seo_robots && {name: 'robots', content: seo_robots},
            twitter_card && {name: 'twitter:card', content: twitter_card},
            twitter_site && {name: 'twitter:site', content: twitter_site},
            twitter_title && {name: 'twitter:title', content: twitter_title},
            twitter_description && {name: 'twitter:description', content: twitter_description},
            twitter_creator && {name: 'twitter:creator', content: twitter_creator},
            opengraph_url && {type: 'property', name: 'og:url', content: opengraph_url},
            opengraph_type && {type: 'property', name: 'og:type', content: opengraph_type},
            opengraph_title && {type: 'property', name: 'og:title', content: opengraph_title},
            opengraph_description && {
                type: 'property',
                name: 'og:description',
                content: opengraph_description,
            },
            opengraph_locale && {type: 'property', name: 'og:locale', content: opengraph_locale},
            opengraph_locale_alternate && {
                type: 'property',
                name: 'og:locale:alternate',
                content: opengraph_locale_alternate,
            },
            opengraph_site_name && {
                type: 'property',
                name: 'og:site_name',
                content: opengraph_site_name,
            },
            opengraph_determiner && {
                type: 'property',
                name: 'og:determiner',
                content: opengraph_determiner,
            },
            opengraph_image &&
                opengraph_image.url && {
                    type: 'property',
                    name: 'og:image',
                    content: opengraph_image.url,
                },
            opengraph_video &&
                opengraph_video.url && {
                    type: 'property',
                    name: 'og:video',
                    content: opengraph_video.url,
                },
            opengraph_audio &&
                opengraph_audio.url && {
                    type: 'property',
                    name: 'og:audio',
                    content: opengraph_audio.url,
                },
            facebook_app_id && {type: 'property', name: 'fb:app_id', content: facebook_app_id},
            facebook_page_id && {type: 'property', name: 'fb:page_id', content: facebook_page_id},
        ].filter((x) => !!x),
        link: [
            ...alternate_languages.map(
                ({lang, uid, type}: {lang: string; uid: string; type: string; label: string}) =>
                    uid
                        ? {
                              rel: 'alternate',
                              href: `https://lunii.com${
                                  uid === 'homepage'
                                      ? `/${lang}/`
                                      : type === 'digitalalbum'
                                      ? `${mapProductToProductPagePrefix({catalog: lang, deviceTarget: current?.productDeviceTarget})}/${uid}/`
                                      : `/${lang}/${uid}${uid ? '/' : ''}`
                              }`,
                              hrefLang: lang,
                              key: lang,
                          }
                        : undefined,
            ),
            alternate_languages &&
                current && {
                    rel: 'alternate',
                    href: `https://lunii.com${
                        current.uid === 'homepage'
                            ? `/${current.lang}/`
                            : current.type === 'digitalalbum'
                            ? `${mapProductToProductPagePrefix({catalog: current.lang, deviceTarget: current?.productDeviceTarget})}/${
                                  current.uid
                              }/`
                            : `/${current.lang}/${current.uid}${current.uid ? '/' : ''}`
                    }`,
                    hrefLang: current.lang,
                    key: current.lang,
                },
            {
                rel: 'preload',
                as: 'script',
                href: 'https://cdnjs.cloudflare.com/ajax/libs/lazysizes/5.3.2/lazysizes.min.js',
            },
        ].filter((x) => !!x),
        style: [].filter((x) => !!x),
        script: [
            {
                src: 'https://cdnjs.cloudflare.com/ajax/libs/lazysizes/5.3.2/lazysizes.min.js',
                type: 'text/javascript',
                async: true,
            },
        ].filter((x) => !!x),
        noscript: [].filter((x) => !!x),
    };
}

export function buildExtraLinkFromData({url_android_target, url_ios_target}: any = {}) {
    return {
        url_android_target,
        url_ios_target,
    };
}

// base product comes from default locale (ex: fr-fr)
// full product comes from locales (ex: fr-be)
export async function convertProductForLevel(baseProduct: any, level: string, ctx: any, pageLang: string = 'null') {
    if (!baseProduct) return undefined;
    const lang = localeToCatalog(pageLang);
    const allProducts = await ctx.context.cache.get('lunii_product_by_ids');
    let fullProduct = allProducts[`${lang}-${baseProduct.reference}`];
    if (!fullProduct) return {notFound: true};
    const prefix = mapProductToProductPagePrefix(fullProduct);
    if (fullProduct.type === 'packs') {
        fullProduct = {
            ...fullProduct,
            languagesAvailable: fullProduct.languagesAvailable && [
                ...fullProduct.languagesAvailable.map((elt: any) => {
                    elt.url = `${prefix}/${elt.slug}/`;
                    return elt;
                }),
            ],
        };
    }
    if (fullProduct.type === 'hardware') {
        // only for hardwares
        const catalog = ((await ctx.context.cache.get('lunii_catalogs')) || {})[
            (fullProduct.catalog || '').toLowerCase()
        ] || {};
        fullProduct.alternateProducts = (catalog.products || []).filter(
            (p: any) =>
                p.subtype === fullProduct.subtype &&
                p.reference !== fullProduct.reference &&
                p.stock,
        );
        if (fullProduct.alternateProducts.length < 1) fullProduct.alternateProducts = null; // remove if no alternate products
    }
    switch (level) {
        case 'page':
            return convertProductForPageLevel(fullProduct);
        case 'module':
            return convertProductForItemLevel(fullProduct, fullProduct.trackingFromDataSource); // for now use module as item
        case 'item':
            return convertProductForItemLevel(fullProduct, fullProduct.trackingFromDataSource);
        default:
            return fullProduct;
    }
}

export function convertProductForPageLevel(product: any) {
    return {
        ...product,
        recommendations:
            product.recommendations &&
            product.recommendations
                .map((reco: any) => ({product: convertProductForItemLevel(reco)}))
                .filter((x: any) => !!x),
    };
}

export function convertProductForItemLevel(product: any, trackingFromDataSource?: string) {
    const types: string[] = [];
    product.themes && types.push(...product.themes.map((t: any) => t.id));
    product.ageRecommendations && types.push(...product.ageRecommendations.map((t: any) => t.id));
    product.localesAvailable && types.push(...product.localesAvailable.map((t: any) => t.id));
    if (product.types) {
        const {id, name, slug} = product.types;
        types.push(id, name, slug);
    }
    return {
        id: product.id,
        alternateProducts: product.alternateProducts,
        objectId: product.objectId,
        reference: product.reference,
        catalog: product.catalog,
        creationDate: product.creationDate,
        locale: product.locale,
        price: product.price,
        type: product.type,
        subtype: product.subtype,
        version: product.version,
        reduction: product.reduction && `-${product.reduction}`,
        oldPrice: product.oldPrice,
        priceExclTax: product.priceExclTax,
        currency: product.currency,
        name: product.name,
        description: product.description,
        shippingInfos: product.shippingInfos,
        included: product.included,
        title: product.name,
        label: [product.ageMin, product.duration].filter((info) => !!info).join(' | '),
        buttonTarget: `https://lunii/add_to_cart${
            trackingFromDataSource ? `?libelle=${trackingFromDataSource}` : ''
        }`,
        image: product.image,
        illustration: product.illustration,
        target:
            product.type === 'packs'
                ? `${mapProductToProductPagePrefix(product)}/${product.slug}/`
                : null, // todo ADD DYNAMIC PAGE TO HARDWARE ON BUILD
        types,
        stock: product.stock,
        slug: product.slug,
        deviceTarget: product.deviceTarget,
    };
}

export async function sha256(str: string) {
    if (!str) return undefined;
    const buf = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(str));
    return Array.prototype.map
        .call(new Uint8Array(buf), (x) => `00${x.toString(16)}`.slice(-2))
        .join('');
}

export function mergeCartItems(newItems?: cart_item[], oldItems?: cart_item[]) {
    if (newItems && !newItems.length) return [];
    // si meme longeur sort les deux
    return (
        newItems &&
        newItems.map((newItem) => {
            const oldItem =
                oldItems && oldItems.find((item) => newItem.reference === item.reference);
            return {
                ...oldItem,
                ...newItem,
            };
        })
    );
}

/**
 * compare array equality. Does not handle case where array includes object
 * @param a {Array}
 * @param b {Array}
 */
export function arrayEquals(a: any[] | undefined, b: any[] | undefined): boolean {
    if (!(a instanceof Array) || !(b instanceof Array)) return false;
    if (a.length !== b.length) return false;

    for (let i = 0, l = a.length; i < l; i++) {
        const aVal = a[i];
        const bVal = b[i];
        if (aVal instanceof Array && bVal instanceof Array) {
            if (!arrayEquals(aVal, bVal)) return false;
        } else if (aVal !== bVal) {
            return false;
        }
    }

    return true;
}

export function convertCurrency(currency: string) {
    if (!currency) return ''; // do not return empty string on prices equal to 0.00
    switch (currency.toLowerCase()) {
        case 'eur':
        case 'euro':
            return '€';
        case 'usd':
            return '$';
        case 'cad':
            return 'CAD';
        default:
            return 'undefined';
    }
}

export function formatGiftCardAsCartItem({
    giftCardName,
    themeReference,
    amount,
    image,
    currency,
    catalog,
    senderName,
    receiverEmail,
    receiverName,
    date,
    message,
}: any) {
    return {
        id: `GIFT_CARD_${Math.random().toString(36).substring(2, 11).toUpperCase()}`,
        reference: 'E-GIFT-CARD-V1',
        quantity: 1,
        product: {
            reference: 'E-GIFT-CARD-V1',
            name: giftCardName,
            price: Math.round(amount * 100),
            priceExclTax: Math.round(amount * 100),
            image,
            currency,
            catalog,
            thumbnailUrl: image,
            type: 'gift_card',
            metadata: {
                amount: Math.round(amount * 100),
                sender: {
                    name: senderName,
                },
                receiver: {
                    email: receiverEmail,
                    name: receiverName,
                },
                sending_date: date?.valueOf(),
                message,
                theme_reference: themeReference,
            },
        },
        libelle: 'carte_cadeau',
        pageModel: 'carte_cadeau',
    };
}

export type setQueryParametersToURLInput = {
    baseURL: string;
    queryParameters: Record<string, string>;
    override?: boolean;
};

export type setQueryParametersToCurrentURLInput = Omit<setQueryParametersToURLInput, 'baseURL'>;

export function setQueryParametersToURL({
    baseURL,
    queryParameters,
    override = false,
}: setQueryParametersToURLInput) {
    const url = new URL(baseURL);

    const searchParamsToObject = Array.from(url.searchParams.entries()).reduce((acc, entry) => {
        acc[entry[0]] = entry[1];
        return acc;
    }, {} as Record<string, string>);

    const newParams = override
        ? new URLSearchParams([...Object.entries(queryParameters)])
        : new URLSearchParams({...searchParamsToObject, ...queryParameters});

    return `${url.protocol}//${url.host}${url.pathname}?${newParams.toString()}`;
}

export function setQueryParametersToCurrentURL({
    queryParameters,
    override = false,
}: setQueryParametersToCurrentURLInput) {
    const newUrl = setQueryParametersToURL({
        baseURL: window.location.href,
        queryParameters,
        override,
    });
    window.history.replaceState({path: newUrl}, '', newUrl);
}

/**
 * Find index of object in an objectArray
 *
 * @param equalityFunction
 * @param arrayToSearch
 */
export function findIndexInObjectArray(equalityFunction: Function, arrayToSearch: any[]): number {
    let foundIndex: number = -1;

    if (!arrayToSearch) {
        return foundIndex;
    }

    for (let index = 0; index < arrayToSearch.length; index++) {
        const currentItem = arrayToSearch[index];
        if (equalityFunction(currentItem)) {
            foundIndex = index;
            break;
        }
    }

    return foundIndex;
}

/**
 * Convert digitalAlbumProduct into a wishlistItemObject. Add missing fields
 *
 * @param product
 */
export function convertDigitalAlbumProductToWishlistItem(
    product: digital_album_product,
): wishlist_item {
    const {
        id,
        objectId,
        reference,
        catalog,
        creationDate,
        name,
        locale,
        price,
        priceExclTax,
        oldPrice,
        reduction,
        currency,
        type,
        image,
        slug,
        types,
    } = product;

    return {
        id,
        objectId,
        reference,
        catalog,
        creationDate,
        name,
        title: name,
        image,
        label: '',
        locale,
        price,
        priceExclTax,
        oldPrice,
        reduction,
        currency,
        type,
        types,
        slug,
        target: '',
        buttonTarget: '',
        canPurchaseWithSubscription: true,
        stock: false,
        _removed: false,
    };
}

export const targetFlamOnlyAudiobook = (target?: string[]) => target && target.indexOf('flam') !== -1 && target.length === 1;

export const getAudiobookProductPageUrl = (locale: string, deviceTarget?: 'fah' | 'flam') => getProductPagePrefixMapping(locale, deviceTarget).replace('{lang}', locale);

// used for pageModel redirections, type is the hardware subtype, return string matches the page model on prismic
export const convertProductTypeForPageModel = (type: string = '') => {
    if (type.indexOf('fah') === 0) return 'mfah';
    if (type.indexOf('odile') === 0) return 'coque-odile';
    if (type.indexOf('flam-case') === 0) return 'coque-flam';
    return 'slugged';
}

export const purifyHtml = (html: string) => html; // deactivated for now, server side issues with dompurify

export const getDefaultLocale = (locale?: string) => Object.keys(stores).find(store => stores[store].indexOf(locale) !== -1) || 'fr-fr';

// map prismic target to locale
export const mapPrismicTarget = (target: any, locale?: string) => {
    if (typeof target !== 'string' || !locale) return target;
    const langToReplace = getDefaultLocale(locale);
    // change only prismic internal targets, aka those who starts with /locale/ (ex: /fr-fr/)
    if (target.slice(0, 7) !== `/${langToReplace}/`) return target;
    return target.replace(new RegExp(`^/${langToReplace}/`), `/${locale}/`);
}

export const sleep = async (time: number) => {
    await new Promise(r => { setTimeout(r, time)} );
}

export async function synchronousPromiseAll<T>(resolvables: (() => Promise<T>)[], sleeper: number|undefined = undefined): Promise<T[]> {
    const results = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const resolvable of resolvables) {
        results.push(await resolvable());
        if (sleeper) await sleep(sleeper);
    }
    return results;
}


export const formatProducts = (products: build_product[], packs: build_pack[], hardware: build_hardware[], types: build_type[], themes: build_theme[], ageRecommendations: build_age_recommendation[]) => {
    const preparedProducts = products.map((p: build_product) => {
        let product = p;
        if (product.type === 'hardware') {
            const h = hardware.find(({reference}) => product.reference === reference);
            if (!h) return null;
            product = {
                ...product,
                ...h,
                name: (h?.localizedNames || []).find(({locale}) => locale === product.catalog)?.name || h.name,
                id: product.id,
                __typename: 'Lunii_HardwareProduct',
            };
        } else if (product.type === 'packs') {
            const pack = packs.find(({reference}) => product.reference === reference);
            const foundType = types.find((type) => type.catalog === product.catalog && type.reference === product.types);
            if (!pack) return null;
            product = {
                ...product,
                ...pack,
                id: product.id,
                __typename: 'Lunii_DigitalAlbumProduct',
                ...(foundType && {
                    types: {
                        id: foundType.id,
                        name: foundType.name,
                        slug: foundType.slug,
                        image: foundType.image,
                    } as {id: string, name: string, slug: string, image: {url: string}}
                }),
                languagesAvailable: product?.languagesAvailable?.map((language) => {
                    const packAvailableId = products.find(({id, catalog}) => language.id === id && catalog === product.catalog)?.objectId;
                    return {
                        ...language,
                        slug: packs.find(({id}) => packAvailableId === id)?.slug,
                    }
                }),
                themes: product.themes?.map((theme) => {
                    const foundTheme = themes.find((t) => t.catalog === product.catalog && t.reference === theme);
                    if (!foundTheme) return null;
                    return {
                        id: foundTheme.id,
                        name: foundTheme.name,
                        slug: foundTheme.slug,
                        image: foundTheme.image,
                    }
                }).filter(x => !!x) as {id: string, name: string, slug: string, image: {url: string}}[],
                ageRecommendations: product.ageRecommendations?.map((ageRecommendation) => {
                    const foundAgeRecommendation = ageRecommendations.find((age) => age.catalog === product.catalog && age.reference === ageRecommendation);
                    if (!foundAgeRecommendation) return null;
                    return {
                        id: foundAgeRecommendation.id,
                        name: foundAgeRecommendation.name,
                        description: foundAgeRecommendation.description,
                        slug: foundAgeRecommendation.slug,
                        image: foundAgeRecommendation.image,
                    }
                }).filter(x => !!x) as {id: string, name: string, description: string, slug: string, image: {url: string}}[],
            }
        }
        return product;
    }).filter(x => !!x) as prepared_product[];
    return preparedProducts.map((product: prepared_product) => ({
        ...product,
        recommendations: product.recommendations.map((recommendationId) => preparedProducts.find(p => product.catalog === p.catalog && p.id === recommendationId)).filter((x: any) => !!x),
    })) as prepared_product[];
}
